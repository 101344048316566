import ProQuestAPIService from "./ProQuestAPIService";
import { FacultyPPQDataDTO, PPQResponseReportModel, PPQResponseReportDTO, FacultyDTO, ContentfulAnswer, RateExceptionReportModel, ExceptionManagementDTO } from "../Models";
import Constants from "../Common/Constants";
import FacultyPPQDataService from "./FacultyPPQDataService";
import PublicAPIService from "./PublicAPIService";
import ADMINFileService from "./ADMINFileService";
import QuestionnaireUtils from "../Common/QuestionnaireUtils";

class ReportService extends ProQuestAPIService {
    async getPPQSummaryReport(contentfulAnswers: ContentfulAnswer[]): Promise<FacultyPPQDataDTO[]> {
        const response = await this.GetAsync<FacultyPPQDataDTO[]>('reports/getppqsummaryreport');

        let facultyPPQData: FacultyPPQDataDTO[] = [];

        if (response.isSuccess) {
            facultyPPQData = response.data;

            facultyPPQData.forEach(data => {
                data.createdon = new Date(data.createdon);
                data.ppqsubmissiondate = new Date(data.ppqsubmissiondate);

                data.specialty = QuestionnaireUtils.getAnswerText(data.specialty, contentfulAnswers);
                data.credentials = QuestionnaireUtils.getAnswerText(data.credentials, contentfulAnswers);
                data.hcptype = QuestionnaireUtils.getAnswerText(data.hcptype, contentfulAnswers);
            });
        }

        return facultyPPQData;
    }

    async getPPQRateExceptions(contentfulAnswers: ContentfulAnswer[]): Promise<RateExceptionReportModel> {
        const response = await this.GetAsync<RateExceptionReportModel>('reports/getppqrateexceptionreport');

        let rateExceptionData: RateExceptionReportModel = { facultyData: [], exceptionData: [] };

        if (response.isSuccess) {
            const facultyPPQData = response.data.facultyData;
            const exceptionData: ExceptionManagementDTO[] = [];

            facultyPPQData.forEach(data => {
                data.createdon = new Date(data.createdon);
                data.specialty = QuestionnaireUtils.getAnswerText(data.specialty, contentfulAnswers);
                data.credentials = QuestionnaireUtils.getAnswerText(data.credentials, contentfulAnswers);
                data.hcptype = QuestionnaireUtils.getAnswerText(data.hcptype, contentfulAnswers);

                const facultyExceptions = response.data.exceptionData.filter(p => p.facultyid === data.facultyid);
                const maxExceptionId = Math.max(...facultyExceptions.map(p => p.id as number));

                const facultyException = facultyExceptions.find(p => p.id === maxExceptionId);

                if (facultyException) {
                    exceptionData.push(facultyException);
                }
            });

            rateExceptionData.facultyData = facultyPPQData;
            rateExceptionData.exceptionData = exceptionData;
        }

        return rateExceptionData;
    }

    async getAllPayerActionItems(contentfulAnswers: ContentfulAnswer[]): Promise<FacultyPPQDataDTO[]> {
        const response = await this.GetAsync<FacultyPPQDataDTO[]>('reports/getppqpayerexceptionreport');

        let facultyPPQData: FacultyPPQDataDTO[] = [];

        if (response.isSuccess) {
            facultyPPQData = response.data;

            facultyPPQData.forEach(data => {
                data.createdon = new Date(data.createdon);
                data.specialty = QuestionnaireUtils.getAnswerText(data.specialty, contentfulAnswers);
                data.credentials = QuestionnaireUtils.getAnswerText(data.credentials, contentfulAnswers);
                data.hcptype = QuestionnaireUtils.getAnswerText(data.hcptype, contentfulAnswers);
            });
        }

        return facultyPPQData;
    }

    private extractPPQResponseData(data: PPQResponseReportDTO, contentfulAnswers: ContentfulAnswer[]) {
        let ppqResponseReports: PPQResponseReportModel[] = [];

        data.facultyPPQs.forEach((facultyPPQ) => {
            const facultyQuests = data.facultyQuests.filter(p => p.facultyid === facultyPPQ.facultyid);

            let ppqResponseReportModel: any = facultyPPQ;
            ppqResponseReportModel.hcptype = QuestionnaireUtils.getAnswerText(facultyPPQ.hcptype, contentfulAnswers);
            ppqResponseReportModel.credentials = QuestionnaireUtils.getAnswerText(facultyPPQ.credentials, contentfulAnswers);

            for (let i = 1; i < Constants.PPQResponseColumnCount; i++) {
                const questionColumnName = `${Constants.PPQResponseQuestionColumnName}${i}`;
                const responseColumnName = `${Constants.PPQResponseResponseColumnName}${i}`;
                const facultyQuest = facultyQuests.filter(p => p.questionno === i);


                let question = '';
                let answer = '';

                if (facultyQuest.length > 0) {
                    question = facultyQuest[0].question;
                }

                facultyQuest.forEach(quest => {
                    const cAnswer = contentfulAnswers.find(p => p.id === quest.answerid);
                    let ans = quest.answer;

                    if (cAnswer && cAnswer.answerText) {
                        ans = cAnswer.answerText;
                    }

                    if (answer.length > 0) {
                        answer = `${answer} ${ans}, `;
                    } else {
                        answer = `${ans}, `;
                    }
                });

                if (answer) {
                    answer = answer.substr(0, answer.length - 2);
                }

                ppqResponseReportModel[questionColumnName] = question;
                ppqResponseReportModel[responseColumnName] = answer;
            }

            ppqResponseReports.push(ppqResponseReportModel);
        });

        return { count: data.count, ppqResponseReports };
    }

    async getAllPPQResponses(
        page: number, pageSize: number, hcpType: string = '',
        searchText: string = '', downloadReportKey: string = '',
        contentfulAnswers: ContentfulAnswer[]): Promise<{ count: number, ppqResponseReports: PPQResponseReportModel[] }> {
        let downloadReportType: 'FILTER' | 'ALL' | '' = '';

        if (downloadReportKey) {
            if (hcpType) {
                downloadReportType = 'FILTER';
            } else {
                downloadReportType = 'ALL';
            }
        }

        const response = await this.PostAsync<PPQResponseReportDTO>('/reports/getppqresponsereport', {
            page,
            pageSize,
            hcpType,
            searchText,
            downloadReportKey,
            downloadReportType,
        });

        if (response.isSuccess) {
            return this.extractPPQResponseData(response.data, contentfulAnswers);
        }

        return { count: 0, ppqResponseReports: [] };
    }

    async downloadPPQResponseReport(page: number, pageSize: number,
        hcpType: string = '', searchText: string = '', contentfulAnswers: ContentfulAnswer[]) {
        const fileKey = 'report/PPQResponseReport.json';
        //create report
        await this.getAllPPQResponses(page, pageSize, hcpType, searchText, fileKey, contentfulAnswers);
        const url = await ADMINFileService.getFile(fileKey, 'application/json; charset=utf-8');

        if (url) {
            const service = new PublicAPIService();
            const response = await service.GetAsync<PPQResponseReportDTO>(url);
            const responseData = this.extractPPQResponseData(response.data, contentfulAnswers);

            const dataToExport = responseData.ppqResponseReports.map((ppqResponseData: any) => {
                const data: any = {
                    facultyid: ppqResponseData.facultyid,
                    ppqsubmissiondate: ppqResponseData.ppqsubmissiondate,
                    firstname: ppqResponseData.firstname,
                    lastname: ppqResponseData.lastname,
                    hcptype: ppqResponseData.hcptype,
                    specialty: ppqResponseData.specialty,
                    points: ppqResponseData.points,
                    lillygroup: ppqResponseData.lillygroup,
                    lillyrate: ppqResponseData.lillyrate,
                    lillytier: ppqResponseData.lillytier,
                    bigroup: ppqResponseData.bigroup,
                    birate: ppqResponseData.birate,
                    bitier: ppqResponseData.bitier,
                };

                for (let i = 1; i < Constants.PPQResponseColumnCount; i++) {
                    const questionColumnName = `${Constants.PPQResponseQuestionColumnName}${i}`;
                    const responseColumnName = `${Constants.PPQResponseResponseColumnName}${i}`;

                    data[questionColumnName] = ppqResponseData[questionColumnName];
                    data[responseColumnName] = ppqResponseData[responseColumnName];
                }

                return data;
            });

            return dataToExport;
        }

        return [];
    }

    async getPPQPendingTieringReport(): Promise<FacultyDTO[]> {
        const result = await FacultyPPQDataService.getPendingTieringData();
        return result.faculties.sort((a, b) => {
            if (a.updatedon && b.updatedon) {
                return new Date(b.updatedon).valueOf() - new Date(a.updatedon).valueOf();
            }

            return -1;
        });
    }
}

const reportService = new ReportService();
export default reportService;