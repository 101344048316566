import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Paper,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import jwt_decode from "jwt-decode";
import FileUpload from "../../Components/File/FileUpload";
import SingleSelect from "../../Components/Select/SingleSelect";
import TextInputField from "../../Components/TextInput/TextInputField";
import TextAreaInputField from "../../Components/TextInput/TextAreaInputField";
import CheckBox from "../../Components/Checkbox/Checkbox";
import RadioButton from "../../Components/RadioButton/RadioButton";
import { Header, StyleGrid } from "./App.style";
import {
  FMVRateExceptionDTO,
  FMVFileUploadDTO,
  EmailDTO,
  RateExceptionAuditDTO,
} from "../../Models";
import {
  FileType,
  CustomEventType,
  DocumentType,
  ValidationType,
  EventType,
} from "../../Common/Enums";
import FileUploadModel from "../../Components/File/FileUploadModel";
import SelectModel from "../../Components/Select/SelectModel";
import CheckboxModel from "../../Components/Checkbox/CheckboxModel";
import SingleAutoComplete from "../../Components/Select/SingleAutoComplete";
import FacultyDetailCard from "../../Components/Card/FacultyDetailCard";
import { CustomEventHandlerService } from "../../Services";
import {
  FacultyPPQDataService,
  AuthProviderService,
  FMVRateExceptionService,
  ContentfulService,
} from "../../Services";

import {
  FunctionRequestingExceptionData,
  BusinessUnitRequestingExceptionData,
  ThirdPartyContractData,
  LillyExceptionSoughtData,
  BIExceptionSoughtData,
  LeaderEngagementPlanData,
  RRByExternalPartyData,
  AssociatedWithActivityData,
} from "../../Data/FMVRateExceptionData";
import UploadedDoc from "./DocumentsAttached";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import Alert from "../../Components/Alert/Alert";
import RadioModel from "../../Components/RadioButton/RadioModel";
import Utils from "../../Common/Utils";
import SingleCheckbox from "../../Components/Checkbox/SingleCheckbox";

interface LabelProps {
  text: string;
}
const Label = (props: LabelProps) => {
  return (
    <Typography
      style={{
        fontWeight: 520,
        color: "#000000d6",
        marginRight: 30,
      }}
    >
      {props.text}
    </Typography>
  );
};

interface Props {
  rateException?: FMVRateExceptionDTO;
}
interface State {
  rateException: FMVRateExceptionDTO | null;
  fileUploadData: FMVFileUploadDTO;
  files: FileUploadModel[];
  uploadedFile: FileUploadModel;
  isValid: boolean;
  userName: string;
  facultyPPQData: any;
  employeeData: any;
  hcpData: SelectModel[];
  brandDataResult: SelectModel[];
  vpApproverData: any;
  result: FMVRateExceptionDTO;
  request_id: string | undefined;
  tkn: null;
  vpValue: string;
  hcpName: string;
  hcpNameData: string;
  asscociatedActivity: string;
  formValidationState: { name: string; isValid: boolean }[];
  isDoc: boolean;
  isLillyChecked: boolean;
  isBiChecked: boolean;
  focused: boolean;
  requiredFields: string[];
}
class RateExceptionForm extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      rateException: props.rateException ?? ({} as FMVRateExceptionDTO),
      userName: "",
      request_id: "",
      facultyPPQData: [],
      employeeData: [],
      hcpData: [],
      vpApproverData: [],
      brandDataResult: [],
      result: {} as FMVRateExceptionDTO,
      fileUploadData: {} as FMVFileUploadDTO,
      uploadedFile: {} as FileUploadModel,
      isValid: true,
      files: [],
      tkn: null,
      vpValue: "",
      hcpName: "",
      hcpNameData: "",
      isDoc: false,
      isLillyChecked: false,
      isBiChecked: false,
      asscociatedActivity: "",
      formValidationState: [
        { name: "functionrequestingexception", isValid: true },
        { name: "businessunitrequestingexception", isValid: true },
        { name: "howfmvraterejected", isValid: true },
        { name: "howfmvraterejectedother", isValid: true },
        { name: "associatedwithactivity", isValid: true },
        { name: "rateexceptionforlilly", isValid: true },
        { name: "rateexceptionforbi", isValid: true },
        { name: "describecontractactivityhcp", isValid: true },
        { name: "hcpexpertise", isValid: true },
        { name: "isthirdpartycontract", isValid: true },
      ],
      focused: false,
      requiredFields: [
        "functionrequestingexception",
        "businessunitrequestingexception",
        "associatedwithactivityradio",
        "vpapprovername",
        "facultyid",
        "howfmvraterejected",
        "describecontractactivityhcp",
        "ishcponthoughtleaderplan",
        "hcpexpertise",
        "isthirdpartycontract",
      ],
    };
  }

  async componentDidMount() {
    const response = await FacultyPPQDataService.getTieredData();
    this.setState({ facultyPPQData: response.facultyPPQData });
    const brandResponse = await ContentfulService.getBrands();

    const brandData = brandResponse
      .map((q: any) => {
        const text = q.name;
        const value = q.name;
        let result = { text, value };
        return result;
      })
      .sort((a, b) => a.text.localeCompare(b.text));
    //console.log("brandResponse", brandDataResult);
    const data = this.state.facultyPPQData
      .map((p: any) => {
        const name: any = `${p.firstname} ${p.lastname}`;
        const text: any = name;
        const value: any = p.facultyid;
        let result = { text, value };
        return result;
      })
      .sort((a: any, b: any) => a.text.localeCompare(b.text));

    this.setState({ hcpData: data, brandDataResult: brandData });

    const path: any = window.location.pathname;
    const requestid = path.split("/")[2];
    const requestData = await FMVRateExceptionService.getByRequestId(requestid);
    this.setState({ rateException: requestData });
    // console.log("rateException", this.state.rateException);

    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(jwt_decode(token.accessToken));
    const userName = JSON.parse(decodedToken).name;
    const email = JSON.parse(decodedToken).preferred_username;

    //this.state.rateException.vpapprovername = userName;

    //this.setState({ tkn: email });
    if (requestData) {
      if (requestData.requesteremail === email) {
        this.setState({ tkn: email });
      } else {
        window.location.replace(`/myrequest/${email}`);
        return;
      }
    } else {
      this.setState({ tkn: email });
    }

    this.setState((prevState) => {
      let rateException: any = Object.assign({}, prevState.rateException);
      rateException.requestername = userName;
      rateException.requesteremail = email;

      if (rateException.rateexceptionforlilly) {
        rateException.exceptionsoughtforlilly =
          LillyExceptionSoughtData[0].value;
      }

      if (rateException.rateexceptionforbi) {
        rateException.exceptionsoughtforbi = BIExceptionSoughtData[0].value;
      }

      return { rateException };
    });

    if (requestData?.requestid) {
      const hcpName = `${requestData.firstname} ${requestData.lastname}`;
      this.setState({
        request_id: requestData?.requestid,
        hcpNameData: hcpName,
      });
      await this.handleInputChange(requestData.vpapprovername);
      const selectedVPObj = [
        {
          text: requestData.vpapprovername,
          value: requestData.vpapproveremail,
        },
      ];
      this.setState({
        vpApproverData: selectedVPObj,
        vpValue: requestData.vpapproveremail,
      });
      await this.handleHCPInputChange(hcpName);

      if (requestData?.rateexceptionforlilly) {
        this.setState({ isLillyChecked: true });
      }
      if (requestData?.rateexceptionforbi) {
        this.setState({ isBiChecked: true });
      }
      if (requestData.associatedwithactivity.length > 2) {
        this.setState({ asscociatedActivity: "yes" });
      }
      await this.handleInputChange(requestData.vpapprovername);
      await this.handleHCPInputChange(hcpName);
    }

    this.loadDocData();
  }

  // componentDidUpdate () {
  //   const {rateException} = this.state;
  //   const obj = {} as FMVRateExceptionDTO ;
  //   this.setState({rateException: obj})
  // }

  render(): React.ReactNode {
    const {
      rateException,
      hcpData,
      vpApproverData,
      tkn,
      vpValue,
      hcpNameData,
      brandDataResult,
      isDoc,
      files,
      isLillyChecked,
      isBiChecked,
      asscociatedActivity,
      focused,
    } = this.state;

    if (tkn === null) {
      return null;
    } else {
      return (
        <Box>
          {rateException?.requestid ? (
            <Grid container>
              <Link to={`/myrequest/${this.state.tkn}`}>
                <FontAwesomeIcon
                  style={{
                    color: "#ee3a29",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  icon={faArrowLeft}
                  size="lg"
                />
              </Link>
              <Typography
                style={{
                  color: "#313131",
                  marginBottom: 10,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Back
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <Link
                to={`/myrequest/${this.state.tkn}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ color: "#EE3A29", borderColor: "#EE3A29" }}
                  size="medium"
                >
                  View My Requests
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Header variant="h5">
                US External Party Fair Market Value (FMV) Increase Request Form
              </Header>
            </Grid>
          </Grid>

          <form onSubmit={this.handleSubmit}>
            <Card style={{ margin: 20, height: "100%" }}>
              <CardContent style={{ marginLeft: 10 }}>
                <StyleGrid container>
                  <Grid container>
                    {/* <Grid item xs={12} style={{ marginBottom: 30 }}>
                      <Alert id="updated" />
                      <Alert id="created" />
                      <Alert id="saved" />
                    </Grid> */}
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Requestor Name : *" />
                      </Grid>
                      <Grid item xs={7}>
                        <TextInputField
                          isReadonly={true}
                          id="requestername"
                          label="Requestor Name"
                          value={rateException?.requestername}
                          onChange={this.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Function requesting exception: *" />
                      </Grid>
                      <Grid item xs={7}>
                        <SingleSelect
                          isRequired={true}
                          id="functionrequestingexception"
                          label="Select"
                          validationType={ValidationType.REQUIRED}
                          value={rateException?.functionrequestingexception}
                          values={FunctionRequestingExceptionData}
                          onChange={this.handleSelectChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Business Unit requesting exception: *" />
                      </Grid>
                      <Grid item xs={7}>
                        <SingleSelect
                          isRequired={true}
                          id="businessunitrequestingexception"
                          label="Select"
                          validationType={ValidationType.REQUIRED}
                          value={rateException?.businessunitrequestingexception}
                          values={BusinessUnitRequestingExceptionData.sort(
                            (a, b) => a.text.localeCompare(b.text)
                          )}
                          onChange={this.handleSelectChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Is there a marketed brand(s)/product(s)  associated with the activity the exception is being sought for if applicable? *" />
                      </Grid>
                      <Grid item container xs={7}>
                        <Grid item xs={12}>
                          <RadioButton
                            isRequired={true}
                            values={AssociatedWithActivityData}
                            selectedValue={
                              asscociatedActivity === "yes"
                                ? "yes"
                                : rateException?.associatedwithactivity
                            }
                            onChange={this.handleRadioChange}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          {rateException?.associatedwithactivityradio ===
                            "yes" || asscociatedActivity === "yes" ? (
                            <SingleSelect
                              isRequired={true}
                              id="associatedwithactivity"
                              label="Select"
                              validationType={ValidationType.REQUIRED}
                              value={rateException?.associatedwithactivity}
                              values={brandDataResult}
                              onChange={this.handleSelectChange}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Select approver for this request *" />
                        <Typography
                          style={{
                            fontStyle: "italic",
                            color: "#6d6e6e",
                            marginRight: 30,
                          }}
                        >
                          (Senior VP or above in business unit/therapeutic
                          area/budget holder):
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <SingleAutoComplete
                          id="vpapprovername"
                          label="Approver Name"
                          values={vpApproverData}
                          selectedValue={vpValue}
                          validationType={ValidationType.REQUIRED}
                          isRequired={true}
                          onChange={this.handleVPApproverChange}
                          onInputChange={this.handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="HCP Name *" />
                      </Grid>
                      <Grid item xs={7}>
                        <SingleAutoComplete
                          id="facultyid"
                          label="HCP Name"
                          values={hcpData}
                          inputValue={hcpNameData}
                          validationType={ValidationType.REQUIRED}
                          isRequired={true}
                          onChange={this.handleHCPSelectChange}
                          onInputChange={this.handleHCPInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Rate Exception being sought for : *" />
                      </Grid>
                      <Grid item xs={7}>
                        <SingleCheckbox
                        //isRequired={true}
                          id="exceptionsoughtforlilly"
                          selectedValue={
                            rateException != null
                              ? rateException.exceptionsoughtforlilly
                              : undefined
                          }
                          values={LillyExceptionSoughtData}
                          onChange={this.handleLillyCheckSelectChange}
                        />
                        <SingleCheckbox
                        //isRequired={true}
                          id="exceptionsoughtforbi"
                          selectedValue={
                            rateException != null
                              ? rateException.exceptionsoughtforbi
                              : undefined
                          }
                          values={BIExceptionSoughtData}
                          onChange={this.handleBiCheckSelectChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="Requested FMV Rate: *" />
                      </Grid>
                      <Grid item container xs={7}>
                        <Grid item xs={1}>
                          <SingleCheckbox
                            id="rateexceptionsoughtforlilly"
                            //isRequired={true}
                            selectedValue={
                              rateException != null
                                ? rateException.exceptionsoughtforlilly
                                : undefined
                            }
                            values={LillyExceptionSoughtData}
                            onChange={this.handleLillyCheckSelectChange}
                          />
                        </Grid>
                        <Grid item xs={4} style={{ marginRight: 20 }}>
                          {rateException?.exceptionsoughtforlilly ||
                          rateException?.rateexceptionforlilly ||
                          isLillyChecked ? (
                            <TextInputField
                              id="rateexceptionforlilly"
                              label="Requested FMV rate"
                              validationType={ValidationType.REQUIRED}
                              value={rateException?.rateexceptionforlilly}
                              onChange={this.handleChange}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <SingleCheckbox
                          //isRequired={true}
                            id="rateexceptionsoughtforbi"
                            selectedValue={
                              rateException != null
                                ? rateException.exceptionsoughtforbi
                                : undefined
                            }
                            values={BIExceptionSoughtData}
                            onChange={this.handleBiCheckSelectChange}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {rateException?.exceptionsoughtforbi ||
                          rateException?.rateexceptionforbi ||
                          isBiChecked ? (
                            <TextInputField
                              id="rateexceptionforbi"
                              label="Requested FMV rate"
                              validationType={ValidationType.REQUIRED}
                              value={rateException?.rateexceptionforbi}
                              onChange={this.handleChange}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={12}>
                        {rateException?.firstname === undefined ? (
                          <></>
                        ) : (
                          <FacultyDetailCard data={rateException} />
                        )}
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={5}>
                        <Label text="How was assigned FMV rate rejected by the external party? *" />
                      </Grid>
                      <Grid item container xs={7}>
                        <Grid item xs={12}>
                          <SingleSelect
                            isRequired={true}
                            id="howfmvraterejected"
                            label="Select"
                            value={rateException?.howfmvraterejected}
                            validationType={ValidationType.REQUIRED}
                            values={RRByExternalPartyData}
                            onChange={this.handleSelectChange}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          {rateException?.howfmvraterejected === "Other" ? (
                            <TextAreaInputField
                              id="howfmvraterejectedother"
                              focused={focused}
                              value={rateException?.howfmvraterejectedother}
                              onChange={this.handleTextAreaChange}
                              onFocus={this.handleFocus}
                              onBlur={this.handleBlur}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: 30 }} container>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontWeight: 650,
                            fontSize: "1.2rem",
                            color: "#000000d6",
                          }}
                        >
                          Supporting Rationale :
                        </Typography>
                        <Divider
                          orientation="horizontal"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        />
                      </Grid>
                      <Grid style={{ marginBottom: 30 }} container>
                        <Grid item xs={5}>
                          <Label text="Describe the business need/contracted activity requiring this specific HCP?  *" />
                        </Grid>
                        <Grid item xs={7}>
                          <TextAreaInputField
                            id="describecontractactivityhcp"
                            focused={focused}
                            value={rateException?.describecontractactivityhcp}
                            onChange={this.handleTextAreaChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                          />
                        </Grid>
                      </Grid>
                      <Grid style={{ marginBottom: 30 }} container>
                        <Grid item xs={5}>
                          <Label text="Is the activity intended to include this HCP on the Thought Leader Engagement Plan? *" />
                          <Typography
                            style={{
                              fontStyle: "italic",
                              color: "#6d6e6e",
                              marginRight: 30,
                            }}
                          >
                            (If No, enter comments.)
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <CheckBox
                            //isRequired={true}
                            id="ishcponthoughtleaderplan"
                            selectedValue={
                              rateException?.ishcponthoughtleaderplan
                            }
                            values={LeaderEngagementPlanData}
                            onChange={this.handleHCPThoughtCheckSelectChange}
                          />
                          {rateException?.ishcponthoughtleaderplan === "no" ? (
                            <TextAreaInputField
                              id="ishcponthoughtleaderplancomment"
                              focused={focused}
                              value={
                                rateException?.ishcponthoughtleaderplancomment
                              }
                              onChange={this.handleTextAreaChange}
                              onFocus={this.handleFocus}
                              onBlur={this.handleBlur}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid style={{ marginBottom: 30 }} container>
                        <Grid item xs={5}>
                          <Label text="Identify the skills/knowledge/expertise this HCP possesses which may not be captured in the PPQ and support the identified business need/contracted activity? *" />
                        </Grid>
                        <Grid item xs={7}>
                          <TextAreaInputField
                            id="hcpexpertise"
                            focused={focused}
                            value={rateException?.hcpexpertise}
                            onChange={this.handleTextAreaChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                          />
                        </Grid>
                      </Grid>
                      <Grid style={{ marginBottom: 30 }} container>
                        <Grid item xs={5}>
                          <Label text="Is this contract managed via 3rd party? *" />
                        </Grid>
                        <Grid item xs={7}>
                          <CheckBox
                            //isRequired={true}
                            id="isthirdpartycontract"
                            selectedValue={rateException?.isthirdpartycontract}
                            values={ThirdPartyContractData}
                            onChange={this.handleHCPThoughtCheckSelectChange}
                          />
                          {rateException?.isthirdpartycontract === "yes" ? (
                            <Typography
                              style={{
                                marginTop: 10,
                                fontSize: "1rem",
                                color: "#0583D2",
                              }}
                            >
                              3rd party payments made to prescribers are
                              reportable and should be identified in the
                              purchase order using the Global Transparency
                              request form located in Buy@Lilly under 'Other
                              Buying Forms'.
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyleGrid>

                <Grid>
                  <Grid item xs={12}>
                    <Label text="Please attach any supporting documentation here (do not include CV) :" />
                  </Grid>
                  <Divider
                    orientation="horizontal"
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                  <Grid item xs={12}>
                    <Paper>
                      <FileUpload
                        isDocumentUpload={true}
                        onChange={this.handleFileUpload}
                      />

                      {rateException?.requestid || isDoc ? (
                        <UploadedDoc
                          files={files}
                          onDownload={this.handleFileDownload}
                          onDelete={this.handleFileDelete}
                        />
                      ) : (
                        <></>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                {rateException?.vpapproverstatus ===
                  "VP Requested More Information" ||
                rateException?.fmvadminteamstatus ===
                  "FMV Admin Requested More Information" ? (
                  <Grid style={{ marginBottom: 30, marginTop: 50 }} container>
                    <Grid item xs={5}>
                      <Label text="Additional requested information : " />
                    </Grid>
                    <Grid item xs={7}>
                      {rateException?.fmvadminteamstatus ===
                      "FMV Admin Requested More Information" ? (
                        <Grid item container style={{ marginBottom: 15 }}>
                          <Grid item xs={3} style={{ marginBottom: 10 }}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              FMV Admin Comment:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              style={{
                                color: "#6d6e6e",
                              }}
                            >
                              {rateException?.fmvadminteamneedmoreinfo}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              Requester Response:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextAreaInputField
                              placeholder={
                                rateException?.additionalrequestedinformation
                                  ? ""
                                  : "Enter Response Here"
                              }
                              id="additionalrequestedinformation"
                              backgroundColor={
                                rateException?.additionalrequestedinformation
                                  ? ""
                                  : "#e3e5e6"
                              }
                              value={
                                rateException?.additionalrequestedinformation
                              }
                              focused={focused}
                              onChange={this.handleTextAreaChange}
                              onFocus={this.handleFocus}
                              onBlur={this.handleBlur}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {rateException?.vpapproverstatus ===
                      "VP Requested More Information" ? (
                        <Grid item container style={{ marginBottom: 15 }}>
                          <Grid item xs={3} style={{ marginBottom: 10 }}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              VP Approver Comment:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              style={{
                                color: "#6d6e6e",
                              }}
                            >
                              {rateException?.vpapprovercomment}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              Requester Response:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextAreaInputField
                              placeholder={
                                rateException?.vpadditionalrequestedinformation
                                  ? ""
                                  : "Enter Response Here"
                              }
                              id="vpadditionalrequestedinformation"
                              value={
                                rateException?.vpadditionalrequestedinformation
                              }
                              focused={focused}
                              backgroundColor={
                                rateException?.vpadditionalrequestedinformation
                                  ? ""
                                  : "#e3e5e6"
                              }
                              onChange={this.handleTextAreaChange}
                              onFocus={this.handleVPCommentFocus}
                              onBlur={this.handleBlur}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid
                  container
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ marginTop: 50 }}
                >
                  <Grid item xs={6}>
                    <Button
                      disabled={
                        rateException?.exceptionrequeststatus === "Pending"
                          ? true
                          : false
                      }
                      variant="outlined"
                      color="secondary"
                      onClick={this.handleSaveDraft}
                    >
                      Save as Draft
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="secondary" type="submit" disabled={this.isFormValid()}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Box>
      );
    }
  }
  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleVPCommentFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({ focused: false });
  };
  DetectOS = (window: any) => {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    return operatingSystem;
  };

  OS = (window: any) => {
    return this.DetectOS(window);
  };

  DetectDevice = () => {
    console.log(`Device is : ${isMobile ? "Mobile" : "Desktop"}`);
  };

  handleSaveDraft = async () => {
    const rateExceptionStatus: any = this.state.rateException;
    rateExceptionStatus.exceptionrequeststatus = "Saved";

    const response = await FMVRateExceptionService.add(
      this.state.rateException
    );
    //console.log("response",response,this.state.rateException)
    if (
      this.state.rateException?.requestid &&
      this.state.rateException?.exceptionrequeststatus === "Saved"
    ) {
      const updateResponse = await FMVRateExceptionService.update(
        this.state.rateException,
        this.state.rateException.requestid
      );
      if (updateResponse) {
        const { uploadedFile } = this.state;

        const fileUploadResponse = await FMVRateExceptionService.uploadFile(
          uploadedFile,
          DocumentType.REQUESTER,
          updateResponse.requestid,
          updateResponse.customerid,
          updateResponse.facultyid
        );
        // console.log('FileResponse', response);
        if (fileUploadResponse) {
          localStorage.setItem("Id", "saved");
          window.location.href = `/myrequest/${this.state.tkn}`;
          this.loadDocData();
        } else {
          localStorage.setItem("Id", "saved");
          window.location.href = `/myrequest/${this.state.tkn}`;
        }
        // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        //   alertId: "saved",
        //   message: "Request has been saved successfully.",
        // });
      }
    }

    if (response) {
      const { uploadedFile } = this.state;

      const fileUploadResponse = await FMVRateExceptionService.uploadFile(
        uploadedFile,
        DocumentType.REQUESTER,
        response.requestid,
        response.customerid,
        response.facultyid
      );
      // console.log('FileResponse', response);
      if (fileUploadResponse) {
        localStorage.setItem("Id", "saved");
        window.location.href = `/myrequest/${this.state.tkn}`;
        this.loadDocData();
      }else{
        localStorage.setItem("Id", "saved");
        window.location.href = `/myrequest/${this.state.tkn}`
      }

      // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
      //   alertId: "saved",
      //   message: "Request has been saved successfully.",
      // });
    }
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.state.rateException?.requestid) {
      if (this.state.rateException.exceptionrequeststatus === "Saved") {
        const rateExceptionStatus: any = this.state.rateException;
        // rateExceptionStatus.exceptionrequeststatus = "Submitted";
        rateExceptionStatus.exceptionrequeststatus = "Pending";
        const updateResponse = await FMVRateExceptionService.update(
          this.state.rateException,
          this.state.rateException.requestid
        );
        if (updateResponse) {
          const { uploadedFile, rateException } = this.state;

          const fileUploadResponse = await FMVRateExceptionService.uploadFile(
            uploadedFile,
            DocumentType.REQUESTER,
            rateException.requestid,
            rateException.customerid,
            rateException.facultyid
          );
          if (this.state.rateException.requestid) {
            const exceptionAudit: RateExceptionAuditDTO = {
              requestid: this.state.rateException.requestid,
              facultyid: this.state.rateException.facultyid,
              actiontakenby: "Requester",
              actiontype: "Created",
              actiondate: new Date(),
              actiondetail: "New request has been created",
            };

            await FMVRateExceptionService.createAudit(exceptionAudit);
          }
          const vpEmail = this.state.rateException?.vpapproveremail;
          const emailObj: EmailDTO = {
            includeAdminInCC: true,
            to: vpEmail,
            cc: this.state.rateException.requesteremail,
            subject: `FMV Exception Request for ${this.state.rateException.firstname} ${this.state.rateException.lastname}`,
            body: `<p>Hello,</p><p>An FMV exception request has been received by the Lilly FMV team and requires your review and approval.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${updateResponse?.requestid}> here</a> and enter your decision in the ‘Senior VP Approver Decision’ section at the bottom of the  screen.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
          };
          const emailResponse = await FMVRateExceptionService.sendEmail(
            emailObj
          );
          localStorage.setItem("Id", "created");
          if (fileUploadResponse) {
            this.loadDocData();
          }
          if (emailResponse && fileUploadResponse) {
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else if(emailResponse){
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else{
            return null;
          }
          // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
          //   alertId: "created",
          //   message: "Request has been created successfully.",
          // });
        }
      } else {
        const updateResponse = await FMVRateExceptionService.update(
          this.state.rateException,
          this.state.rateException.requestid
        );
        if (updateResponse) {
          const { uploadedFile, rateException } = this.state;

          const fileUploadResponse = await FMVRateExceptionService.uploadFile(
            uploadedFile,
            DocumentType.REQUESTER,
            rateException.requestid,
            rateException.customerid,
            rateException.facultyid
          );
          // console.log('FileResponse', response);

          if (this.state.rateException.requestid) {
            const exceptionAudit: RateExceptionAuditDTO = {
              requestid: this.state.rateException.requestid,
              facultyid: this.state.rateException.facultyid,
              actiontakenby: "Requester",
              actiontype: "Updated",
              actiondate: new Date(),
              actiondetail: "Request has been updated",
            };

            await FMVRateExceptionService.createAudit(exceptionAudit);
          }
          const vpEmail = this.state.rateException?.vpapproveremail;
          const emailObj: EmailDTO = {
            includeAdminInCC: true,
            to: vpEmail,
            cc: this.state.rateException.requesteremail,
            subject: `FMV Exception Request for ${this.state.rateException.firstname} ${this.state.rateException.lastname}`,
            body: `<p>Hello,</p><p>An FMV exception request has been updated by the Requestor.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${updateResponse?.requestid}> here</a> and enter your decision in the ‘VP Approver Decision’ section at the bottom of the screen.</p><p>Kind regards,</p><p> Lilly FMV</p>`,
          };
          const emailResponse = await FMVRateExceptionService.sendEmail(
            emailObj
          );
          localStorage.setItem("Id", "updated");
          if (fileUploadResponse) {
            this.loadDocData();
          }
          if (emailResponse && fileUploadResponse) {
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else if(emailResponse){
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else{
            return null;
          }

          // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
          //   alertId: "updated",
          //   message: "Request has been updated successfully.",
          // });
        }
      }
    } else {
      const rateExceptionStatus: any = this.state.rateException;
      // rateExceptionStatus.exceptionrequeststatus = "Submitted";
      rateExceptionStatus.exceptionrequeststatus = "Pending";

      const response = await FMVRateExceptionService.add(
        this.state.rateException
      );

      if (response) {
        this.setState({ request_id: response?.requestid });
        if (this.state.rateException && response?.requestid) {
          const exceptionAudit: RateExceptionAuditDTO = {
            requestid: response?.requestid,
            facultyid: this.state.rateException.facultyid,
            actiontakenby: "Requester",
            actiontype: "Created",
            actiondate: new Date(),
            actiondetail: "New request has been created",
          };

          await FMVRateExceptionService.createAudit(exceptionAudit);
        }
        const vpEmail = this.state.rateException?.vpapproveremail;
        const requestermail = this.state.rateException?.requesteremail;
        if (vpEmail && requestermail) {
          const { uploadedFile } = this.state;

          const fileUploadResponse = await FMVRateExceptionService.uploadFile(
            uploadedFile,
            DocumentType.REQUESTER,
            response.requestid,
            response.customerid,
            response.facultyid
          );
          const emailObj: EmailDTO = {
            includeAdminInCC: true,
            to: vpEmail,
            cc: requestermail,
            subject: `FMV Exception Request for ${this.state.rateException?.firstname} ${this.state.rateException?.lastname}`,
            body: `<p>Hello,</p><p>An FMV exception request has been received by the Lilly FMV team and requires your review and approval.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${response?.requestid}> here</a> and enter your decision in the ‘Senior VP Approver Decision’ section at the bottom of the screen.</p><p>Kind regards,</p><p> Lilly FMV</p>`,
          };
          localStorage.setItem("Id", "created");
          const emailResponse = await FMVRateExceptionService.sendEmail(
            emailObj
          );
          if (fileUploadResponse) {
            this.loadDocData();
          }
          if (emailResponse && fileUploadResponse) {
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else if(emailResponse){
            window.location.href = `/myrequest/${this.state.tkn}`;
          }else {
            return null;
          }
        }

        // CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        //   alertId: "created",
        //   message: "Request has been created successfully.",
        // });
      }
    }
  };

  handleInputChange = async (value: string) => {
    const { vpApproverData, rateException } = this.state;

    const isData = vpApproverData.find((p: any) => p.text === value);

    if (value.length > 3 && isData === undefined) {
      const response = await FMVRateExceptionService.getEmployee(
        rateException?.vpapprovername === value ? value.split(" ")[0] : value
      );
      // console.log('response', response);
      if (response) {
        const data = response.map((p: any) => {
          const name: any = `${p.FirstName} ${p.LastName}`;
          const text: SelectModel[] = name;
          const value: SelectModel[] = p.EmailAddress;
          let result = { text, value };
          // console.log("value", result);
          return result;
        });

        // console.log('name', data);
        this.setState({ vpApproverData: data });
      }
      // this.setState({ employeeData: response });
      // console.log('vpApproverData', response)
    }
  };

  handleHCPInputChange = async (value: string) => {
    const { rateException } = this.state;

    if (rateException?.firstname) {
      const hcpName = `${rateException.firstname} ${rateException.lastname}`;
      this.setState({ hcpNameData: hcpName });
    }
    this.setState({ hcpNameData: value });
  };

  handleVPApproverChange = async (
    selected: SelectModel,
    targetId?: string,
    isValid?: boolean
  ) => {
    if (targetId) {
      this.setState({ vpValue: selected.value.toString() });
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException.vpapproveremail = selected.value.toString();
        rateException.vpapprovername = selected.text;
        let formValidationState = prevState.formValidationState;
        const index = formValidationState.findIndex((p) => p.name === targetId);
        formValidationState[index] = {
          name: targetId,
          isValid: isValid ? isValid : false,
        };

        return { rateException, formValidationState };
      });
    }
  };
  handleChange = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | undefined,
    isValid: boolean
  ) => {
    if (event) {
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[event.target.id] = event.target.value;
        let formValidationState = prevState.formValidationState;
        const index = formValidationState.findIndex(
          (p) => p.name === event.target.id
        );
        formValidationState[index] = {
          name: event.target.id,
          isValid: isValid,
        };
        return { rateException, formValidationState };
      });
    }
  };

  handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event) {
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[event.target.id] = event.target.value;
        return { rateException };
      });
    }
  };

  handleSelectChange = (
    selected: SelectModel,
    targetId?: string,
    isValid?: boolean
  ) => {
    if (targetId) {
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[targetId] = selected.value;

        let formValidationState = prevState.formValidationState;
        const index = formValidationState.findIndex((p) => p.name === targetId);
        formValidationState[index] = {
          name: targetId,
          isValid: isValid ? isValid : false,
        };

        return { rateException, formValidationState };
      });
    }
  };

  handleHCPSelectChange = async (
    selected: SelectModel,
    targetId?: string,
    isValid?: boolean
  ) => {
    if (targetId) {
      const facultyData = await FacultyPPQDataService.getByFacultyId(
        selected.value
      );
      // console.log("facultyData", facultyData);
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[targetId] = selected.value;
        const facultyId = rateException.facultyid;
        const result = this.state.facultyPPQData.find((data: any) =>
          data.facultyid === facultyId ? true : false
        );
        if (result) {
          rateException.firstname = result.firstname;
          rateException.lastname = result.lastname;
          rateException.hcptype = result.hcptype;
          rateException.lillycurrentgrouptier = `${result.lillytier}${result.lillygroup}`;
          rateException.bicurrentgrouptier = `${result.bitier}${result.bigroup}`;
          rateException.lillycurrentrate = result.lillyrate;
          rateException.bicurrentrate = result.birate;
          rateException.lillygroup = result.lillygroup;
          rateException.bigroup = result.bigroup;
          rateException.customerid = result.customerid;
          rateException.lillypointtonexttier = facultyData?.LillyNextTierPoints;
          rateException.bipointtonexttier = facultyData?.BINextTierPoints;
        }
        // this.setState({result:result})
        // console.log("hcpfaculty", result);
        let formValidationState = prevState.formValidationState;
        const index = formValidationState.findIndex((p) => p.name === targetId);
        formValidationState[index] = {
          name: targetId,
          isValid: isValid ? isValid : false,
        };

        return { rateException, formValidationState };
      });
    }
  };

  handleLillyCheckSelectChange = (
    selected: CheckboxModel,
    eventType: EventType
  ) => {
    let isLillyChecked = false;
    let value = "";

    if (eventType === EventType.REMOVE) {
      isLillyChecked = false;
      value = "";
    } else {
      isLillyChecked = true;
      value = selected.value.toString();
    }

    this.setState((prevState) => {
      let rateException: any = Object.assign({}, prevState.rateException);
      rateException["exceptionsoughtforlilly"] = value;

      if (!value) {
        rateException["rateexceptionforlilly"] = "";
      }

      return { rateException, isLillyChecked };
    });
  };

  handleBiCheckSelectChange = (
    selected: CheckboxModel,
    eventType: EventType
  ) => {
    let isBiChecked = false;
    let value = "";

    if (eventType === EventType.REMOVE) {
      isBiChecked = false;
      value = "";
    } else {
      isBiChecked = true;
      value = selected.value.toString();
    }

    this.setState((prevState) => {
      let rateException: any = Object.assign({}, prevState.rateException);
      rateException["exceptionsoughtforbi"] = value;

      if (!value) {
        rateException["rateexceptionforbi"] = "";
      }

      return { rateException, isBiChecked };
    });
  };

  handleHCPThoughtCheckSelectChange = (
    selected: CheckboxModel,
    targetId?: string
  ) => {
    if (targetId) {
      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[targetId] = selected.value;
        // console.log(selected.value);
        return { rateException };
      });
    }
  };
  handleRadioChange = (selected: RadioModel) => {
    //console.log(targetId)

    this.setState((prevState) => {
      let rateException: any = Object.assign({}, prevState.rateException);
      rateException.associatedwithactivityradio = selected.value;
      if (selected.value === "no") {
        rateException.associatedwithactivity = selected.value;
        this.setState({ asscociatedActivity: "no" });
      }
      if (selected.value === "yes") {
        rateException.associatedwithactivity = "";
        this.setState({ asscociatedActivity: "yes" });
      }

      return { rateException };
    });
  };

  handleMultiSelectChange = (
    selected: SelectModel[],
    targetId?: string,
    isValid?: boolean
  ) => {
    if (targetId) {
      const values = selected.map((p) => p.value).join(",");

      this.setState((prevState) => {
        let rateException: any = Object.assign({}, prevState.rateException);
        rateException[targetId] = values;

        // let formValidationState = prevState.formValidationState;
        // const index = formValidationState.findIndex(p => p.name === targetId);
        // formValidationState[index] = { name: targetId, isValid: isValid ? isValid : false };

        return { rateException };
      });
    }
  };

  handleFileUpload = async (file: FileUploadModel) => {
    // console.log(file);
    let { rateException, fileUploadData, request_id } = this.state;
    const files: FileUploadModel[] = [];
    let fileModel = new FileUploadModel();
    fileModel = file;
    if (rateException) {
      file.facultyId = rateException.facultyid;
      fileUploadData.customerid = rateException.customerid;
      fileUploadData.facultyid = rateException.facultyid;
      fileUploadData.filename = file.name;
      fileUploadData.id = file.id;
      fileUploadData.requestid = request_id;
      fileUploadData.filetype = file.type;
      //rateException.documentsattached = file;
      files.push(file);
      this.setState({ fileUploadData, uploadedFile: file });
      this.setState({ files, isDoc: true });
      // console.log("fileUpload", fileUploadData);
      // const response = await FMVRateExceptionService.uploadFile(
      //   file,
      //   DocumentType.REQUESTER,
      //   request_id,
      //   rateException.customerid,
      //   rateException.facultyid
      // );
      // // console.log('FileResponse', response);
      // if (response) {
      //   this.loadDocData();
      // }
    }
    // console.log("key", fileModel.sizeInKB);
    if (fileModel.sizeInKB > 10000) {
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "fileUpload",
        message: "Error in file upload",
      });
      // console.log("size exceeded");
    } else {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "fileUpload",
        message: "file uploaded successfully",
      });
    }

    return { rateException };
  };

  loadDocData = async () => {
    const { request_id, rateException } = this.state;

    const requestid: any = rateException?.requestid
      ? rateException.requestid
      : request_id;
    // console.log("requestid", requestid);
    if (requestid) {
      const exceptionFiles = await FMVRateExceptionService.getAllFile(
        requestid,
        DocumentType.REQUESTER
      );
      // console.log("exceptionfiles",exceptionFiles)
      const files: FileUploadModel[] = [];

      exceptionFiles
        .filter((file) => file.documenttype === DocumentType.REQUESTER)
        .forEach((exceptionFile) => {
          const file = new FileUploadModel();

          file.id = exceptionFile.id;
          file.name = exceptionFile.filename;
          file.facultyId = exceptionFile.facultyid;
          file.type =
            FileType[exceptionFile.documenttype as keyof typeof FileType];
          file.s3filekey = exceptionFile.s3filekey;
          file.sizeInKB = exceptionFile.fileSizeInKB ?? 0;

          files.push(file);
        });

      this.setState({ files, isDoc: true });
    }
  };

  handleFileDownload = async (file: FileUploadModel) => {
    const downloadURL = await FMVRateExceptionService.getFile(
      file.s3filekey,
      file.type
    );
    Utils.openUrlParent(downloadURL);
  };

  handleFileDelete = async (file: FileUploadModel) => {
    const { request_id } = this.state;
    if (request_id) {
      const isSuccess = await FMVRateExceptionService.deleteFile(
        file,
        DocumentType.REQUESTER
      );
      let message = "";

      if (isSuccess) {
        const { files } = this.state;

        const index = files.findIndex(
          (p) => p.name === file.name && p.type === file.type
        );
        files.splice(index, 1);

        this.setState({ files }, () => {
          const fileInput = document.getElementById("otherUpload") as HTMLInputElement;

          if (fileInput) {
              fileInput.value = '';
          }
        });

        message = "File Deleted successfully";
        CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
          alertId: "fileUpload",
          message,
        });
      } else {
        message = "Failed to delete File";
        CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
          alertId: "fileUpload",
          message,
        });
      }
    } else {
      const { files } = this.state;

      const index = files.findIndex(
        (p) => p.name === file.name && p.type === file.type
      );
      files.splice(index, 1);

      this.setState({ files }, () => {
        const fileInput = document.getElementById("otherUpload") as HTMLInputElement;

        if (fileInput) {
            fileInput.value = '';
        }
      });
      let message = "File Deleted successfully";
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "fileUpload",
        message,
      });
    }
  };

  isFormValid = () => {
    const { requiredFields } = this.state;
    const rateException: any = this.state.rateException;
    let fieldValidations: boolean[] = [];    

    if (rateException) {
      requiredFields.forEach((fieldName) => {
        const fieldValue = rateException[fieldName];
        if (fieldName === 'associatedwithactivityradio') {
          const isExists = rateException.hasOwnProperty(fieldName);

          if (isExists) {
            if (fieldValue) {
              fieldValidations.push(true);
            } else {
              fieldValidations.push(false);
            }
          } else {
            fieldValidations.push(true);
          }
        } else {
          if (fieldValue) {
            fieldValidations.push(true);
          } else {
            fieldValidations.push(false);
          }
        }
      });

      if (rateException['associatedwithactivityradio'] === 'yes') {
        if (rateException['associatedwithactivity']) {
          fieldValidations.push(true);
        } else {
          fieldValidations.push(false);
        }
      }

      if (rateException['exceptionsoughtforlilly'] || rateException['exceptionsoughtforbi']) {
        if (rateException['exceptionsoughtforlilly']) {
          if (rateException['rateexceptionforlilly']) {
            fieldValidations.push(true);
          } else {
            fieldValidations.push(false);
          }
        }
        if (rateException['exceptionsoughtforbi']) {
          if (rateException['rateexceptionforbi']) {
            fieldValidations.push(true);
          } else {
            fieldValidations.push(false);
          }
        }
      } else {
        fieldValidations.push(false);
      }

      if (rateException['howfmvraterejected'] === "Other") {
        if (rateException['howfmvraterejectedother']) {
          fieldValidations.push(true);
        } else {
          fieldValidations.push(false);
        }
      }

      if (rateException['ishcponthoughtleaderplan'] === "no") {
        if (rateException['ishcponthoughtleaderplancomment']) {
          fieldValidations.push(true);
        } else {
          fieldValidations.push(false);
        }
      }
    }

    return fieldValidations.filter(p => p === false).length > 0;
  };
}

export default RateExceptionForm;
